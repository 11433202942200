import { useStaticQuery, graphql } from "gatsby"

export const FetchClinics = () => {
  const query = useStaticQuery(
    graphql`
      query Clinics {
        allContentfulClinic {
          nodes {
            id
            name
            slug
          }
        }
      }
    `
  )
  return query.allContentfulClinic.nodes
}
