import _ from "lodash"
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js"
import { FetchClinics } from "services/graphQl/queries/clinics"

export const getSelectOptions = (source, options, excludedClinic = null) => {
  switch (source) {
    case "Clinics": {
      let clinics = _.sortBy(FetchClinics(), ["slug"])

      if (excludedClinic) {
        clinics = clinics.filter(clinic => clinic.slug !== excludedClinic)
      }

      return clinics?.map(clinic => ({
        id: clinic.id,
        label: clinic.name,
        value: clinic.name,
      }))
    }

    default:
      return options?.map((option, key) => {
        return { id: key, label: option, value: option }
      })
  }
}

export const getFieldReference = field => {
  const reference = {}

  if (field.required) {
    reference.required = "Please fill out this field"
    reference.validate = value => {
      return !value.trim() ? "Please fill out this field" : true
    }
  }

  if (field.type === "email") {
    reference.pattern = {
      message: "invalid email address",
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    }
  }

  if (field.type === "phone-number") {
    reference.validate = phone => {
      if (phone) {
        const parsedPhoneNumber = parsePhoneNumberFromString(`+${phone}`)
        const isValid = !!isValidPhoneNumber(
          phone.substring(parsedPhoneNumber?.countryCallingCode?.length || 0),
          parsedPhoneNumber?.country
        )
        return isValid ? true : "Invalid phone number"
      }
      return true
    }
  }

  return reference
}

export const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
